@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-ExtraBd.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-ExtraBd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-Bold.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-Demi.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-ExtraBdItalic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-ExtraBdItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-DemiItalic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-DemiItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-Heavy.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-ExtraLight.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-XlightItalic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-XlightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-HeavyItalic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-Light.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-LightItalic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-Medium.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF Normal';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-Normal.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-Normal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-MediumItalic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF Normal';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-NormalItalic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-NormalItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-Regular.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-Italic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-Thin.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Articulat CF';
  src: url('/assets/fonts/TryMe/SVN-ArticulatCF-ThinItalic.woff2') format('woff2'),
      url('/assets/fonts/TryMe/SVN-ArticulatCF-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
