[nz-icon] {
  font-size: 1.5rem;
}

.align-icon {
  vertical-align: 0.05rem;

  &[nz-icon] {
    vertical-align: 0.075rem;
  }
}
