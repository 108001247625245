@import '../tryme/theme.less';

@btn-border-radius-base: @tryme-button-border-radius;
@btn-text-hover-bg: @tryme-gray-100;

@btn-default-color: @tryme-primary;
@btn-default-bg: @tryme-white;
@btn-default-border: @tryme-primary;

@btn-height-sm: 28px;

// Button icon smaller and place appropriate on vertical alignment
[nz-button] {
  @apply font-semibold;

  [nz-icon][nztype='loading'] {
    @apply mr-1;
    font-size: 0.5rem;
    vertical-align: 0.15rem;
  }
}

.ant-btn-primary:hover {
  @apply bg-primary-500 border-primary-500;
}
.ant-btn-primary:focus {
  @apply bg-primary-400 border-primary-400;
}
.ant-btn-primary:active {
  @apply bg-primary-600 border-primary-600;
}

.ant-btn-lg {
  border-radius: 12px;

  [nz-icon][nztype='loading'] {
    font-size: 1rem;
    vertical-align: 0.2rem;
  }
}

.ant-btn.btn-cancel {
  @apply bg-gray-300 border hover:bg-gray-400 border-gray-200 text-white;
}
