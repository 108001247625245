@import '../tryme/mixins.less';
@import '../tryme/theme.less';

.bg-loading {
  @apply bg-gray-50;
  @apply bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100;
}

.bg-move {
  background-size: 200% 100%;
  animation: 1.5s move cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

.avatar-ring {
  @apply border-transparent border-solid rounded-full;
  outline: 2.5px solid @tryme-red;
  border-width: 3px;
  margin: 2px;
}

.avatar img {
  @apply bg-primary-50;
  @apply bg-gradient-to-r from-purple-300 via-pink-300 to-purple-300;
  .bg-move();
}

.avatar-white {
  img {
    @apply bg-primary-300;
  }
  &.bg-move img {
    @apply bg-primary-50;
    @apply bg-gradient-to-r from-gray-200 via-gray-300 to-gray-100;
    .bg-move();
  }
}

.tryme-container {
  .tryme-container();
}

@keyframes move {
  to {
    background-position-x: -200%;
  }
}

.header-see-all {
  @apply flex justify-between items-center;

  strong {
    @apply font-medium cursor-pointer text-primary-300 hover:text-primary-500;
  }
}

.tryme-section {
  @apply h-full border border-gray-100 rounded-xl shadow-lg overflow-hidden;
}

.back-icon {
  @apply rotate-180 p-1 mr-1 rounded-full !text-gray-900 hover:bg-gray-100;
  vertical-align: 0.075rem;
}
