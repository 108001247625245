@modal-body-padding: 40px;

.ant-modal.confirm-dialog {
  .ant-modal-body {
    @apply pb-0;
  }

  .ant-modal-footer {
    @apply flex flex-row-reverse space-x-3 py-4;

    .ant-btn {
      min-width: 8rem;
      height: 2.5rem;
      @apply text-base rounded-xl;

      &:not(.ant-btn-primary) {
        @apply ml-3 bg-gray-300 border hover:bg-gray-400 border-gray-200 text-white;
      }
    }
  }
}

.ant-modal.post-modal {
  @apply top-0 p-0 m-0;
  @apply max-w-full w-full h-screen;

  .ant-modal-content {
    @apply rounded-none h-full w-screen;

    .ant-modal-close {
      @apply right-auto left-0 text-white hover:text-gray-100;
    }

    .ant-modal-body {
      @apply p-0 h-full w-full;

      post-detail {
        @apply h-full;
      }
    }
  }
}

.ant-modal.poster {
  @apply top-0 h-full
  flex justify-center items-center;


  .ant-modal-content {
    @apply p-0 bg-transparent shadow-none;
  }
}
