@import '../custom/tryme/theme.less';

html,
body {
  // @apply dark:bg-gray-800 dark:text-gray-100;
  font-family: @tryme-font-family;
}

// h1,
// h2 ,
// h3,
// h4 ,
// h5,
// h6 {
//   @apply text-black dark:text-white;
// }

h1,
h2 {
  @apply font-bold;
}
h3,
h4 {
  @apply font-semibold leading-tight;
}
h5,
h6 {
  @apply font-semibold;
}

h1 {
  @apply text-4xl;
}
h2 {
  font-size: 22px;
}
h3 {
  @apply text-base;
}
h4 {
  @apply text-lg;
}
h5 {
  @apply text-sm;
}

h6 {
  @apply text-xs;
}

img {
  display: inline-block;
}

a {
  @apply text-gray-800 hover:text-tryme-primary;

  &.primary {
    @apply text-tryme-primary hover:text-primary-600;
  }
}

code {
  @apply bg-gray-100 text-gray-800 px-2 py-1 rounded;
}

strong {
  @apply font-semibold;
}

p {
  @apply leading-tight;
}

small {
  @apply text-xs;
}

figure {
  @apply mb-0;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  font-family: @tryme-font-family;
  overflow: inherit;
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
