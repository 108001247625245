.ant-message .anticon {
  @apply -top-1;
}

.ant-message-notice {
  @apply text-right py-1;
  font-size: 13px;

  .anticon-info-circle {
    @apply !text-tryme-tertiary;
  }

  .ant-message-notice-content {
    padding: 8px 10px 6px;
  }
}
