@import '../tryme/theme.less';

@item-hover-bg: @tryme-gray-100;

// Form
@form-item-margin-bottom: 16px;

// Input
@input-placeholder-color: @tryme-gray-400;
@input-color: @tryme-gray-900;
@input-border-color: transparent;
@input-bg: @tryme-gray-100;
@input-hover-border-color: @tryme-gray-300;

[nz-input],
[nz-date-picker] {
  @apply caret-tryme-secondary;
  text-indent: @tryme-input-border-radius / 2;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  @apply shadow-none border-gray-300;
}

// Calendar
@picker-bg: @tryme-gray-100;
@picker-border-color: transparent;

// Select
@select-background: @tryme-gray-100;
@select-single-item-height-lg: 50px;
@select-dropdown-font-size: 16px;
@select-border-color: transparent;
.ant-select-arrow {
  @apply w-auto h-auto;
  @apply -translate-y-1/4;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply bg-gray-200 font-semibold;
}
.ant-select-selection-item {
  @apply font-semibold;
}

// Form control label
nz-form-label {
  @apply text-base font-semibold;
}
.ant-form-item-explain {
  @apply mt-1;
}

// Radio
@radio-size: 20px;
@radio-dot-size: 14px;

// .ant-radio-inner::after {
//   left: 2.5px;
// }
